import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SendContact } from "../API/Api";

export interface EmailSentFunction {
  (success: boolean): void;
}
export interface ContactFormProps {
  emailSent: EmailSentFunction;
}

const ContactForm = (props: ContactFormProps) => {
  const recaptchaRef: any = React.createRef();

  const emailTargets = [
    "Secretary",
    "Catering",
    "Chairman",
    "Coaching Team",
    "Records Officer",
    "Safeguarding",
    "Treasurer",
    "Website Maintainer",
  ];

  const [target, setTarget] = useState("Secretary");
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const sendEmail = (token: any) => {
    SendContact({
      token,
      emailAddress,
      message,
      name,
      subject,
      target,
    }).then((emailSent) => props.emailSent(emailSent));
  };

  const isFormValid = () => {
    //  eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      name !== "" &&
      emailAddress !== "" &&
      subject !== "" &&
      message !== "" &&
      emailRegex.test(emailAddress)
    );
  };

  return (
    <form
      onSubmit={(e) => {
        setSending(true);
        recaptchaRef.current.execute();
        e.preventDefault();
      }}
      className="text-left"
    >
      <div className="form-group">
        <label htmlFor="targetSelect">Who would you like to contact?</label>
        <select
          id="targetSelect"
          className="custom-select"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
        >
          {emailTargets.map((t) => (
            <option value={t} key={t}>
              {t}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="nameInput">Name</label>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="nameInput"
          placeholder="John Smith"
        />
      </div>

      <div className="form-group">
        <label htmlFor="emailInput">Email Address</label>
        <input
          type="text"
          className="form-control"
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          id="emailInput"
          placeholder="John.Smith@gmail.com"
        />
      </div>

      <div className="form-group">
        <label htmlFor="subjectInput">Subject</label>
        <input
          type="text"
          className="form-control"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          id="subjectInput"
          placeholder="Subject"
        />
      </div>

      <div className="form-group">
        <label htmlFor="messageInput">Message</label>
        <textarea
          className="form-control"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          id="messageInput"
          rows={5}
        />
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LekXNQeAAAAAGXoi6kLYJn-PsgVCb570qywPJ2U"
        onChange={sendEmail}
      />
      <button
        type="submit"
        className="btn btn-primary"
        disabled={!isFormValid() || sending}
      >
        Send Message
      </button>
    </form>
  );
};

export default ContactForm;
